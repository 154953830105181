.eventCalendarWrapper {
    flex: 100%;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
}

.eventCalendarBody a {
    text-decoration: none;
    color: black;
}

.eventCard-details h3 {
    padding-top: 5px;
}

.eventCalendarHeader {
    background-image: url('../../assets/event_bg.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 75px 0px 75px 0px;
    text-align: center;
}

.eventCalendarHeader h1 {
    color: white;
}

/* New Toggle Container */
.eventCalendarToggle {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.eventCalendarToggle {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  /* Target the ToggleButtonGroup container */
  .eventCalendarToggle .MuiToggleButtonGroup-root {
    border: 1px solid var(--border-color);
    background-color: #f9f9f9;
    border-radius: 30px;
    padding: 4px;
  }
  
  /* Style each ToggleButton */
  .eventCalendarToggle .MuiToggleButton-root {
    text-transform: none;
    padding: 8px 16px;
    border: none;
    color: #555;
    border-radius: 30px;
  }
  
  /* Selected state */
  .eventCalendarToggle .Mui-selected {
    background-color: var(--primary-color) !important;
    color: white !important;
  }
  

/* Material UI Toggle styling */
.event-toggle {
    background-color: #f9f9f9;
    border-radius: 30px;
}
.event-toggle .MuiToggleButton-root {
    border: none;
    color: #555;
    text-transform: none;
}
.event-toggle .Mui-selected {
    background-color: var(--primary-color) !important;
    color: white;
}

.eventCalendarBody ul {
    display: flex;
    flex-wrap: wrap;
    font-size: smaller;
}

.eventCard-wrapper {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 15px;
    width: 350px;
}

.eventCard-img img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.completed-badge {
    color: white;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    background-color: var(--primary-color);
    margin-top: 10px;
}

.upcoming-badge {
    margin-top: 10px;
    color: white;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    background-color: rgb(4, 161, 135);
}

@media screen and (max-width: 600px) {
    .eventCalendarWrapper {
        width: 100%;
        margin: 0;
        overflow-x: hidden;
        box-sizing: border-box;
    }
    
    .eventCalendarBody ul {
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    .eventCard-wrapper {
        width: 95%;
        margin: 5px auto;
        box-sizing: border-box;
    }
}
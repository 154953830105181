.login-bg-container {
display:flex;
width:100% !important;
background-color:#0F1C54;
background-image:url('../../assets/login-bg-1.webp');
background-position: center;
background-size:75%;
background-repeat: no-repeat;
background-position: bottom right;

}


.login-bg-container .auth-form {
    width:400px;
    margin: auto;
    padding: 60px;
    border: 1px solid #ddd;
    background: #fff;
}

.login-bg-container p {
    padding-top:10px;
} 


.login-bg-container .auth-form .logo img {
    text-align: center;
    width: 80px;
    margin-top:0px;
    padding-bottom: 10px;

}
.userProfile {
    flex: 50%;
    min-width:auto;
    border-right: 1px solid var(--border-color);
	  background-color: white;
    overflow-y: scroll;
}

.posts-by-this-user {
  text-align: left;
}

.profile::-webkit-scrollbar {
  display: none;
}


.profileHeader {
  position:sticky;
  top:0;
  background-color: white;
  z-index:100;
  border-bottom: 1px solid var(--border-color);
  padding: 15px 20px;
}


.profileDetailsWrap {

display: flex;
  margin-top: 15px;
  align-items: center;
  border-bottom:1px solid  var(--border-color);
  padding: 10px 25px 10px 25px;

}

.profile-img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.profile-img {
    border-radius:50%;
    width:75px;
    height:75px;
    margin-right: 15px;
}

.edit-profile {
    
    padding-left:100px;
  

}
.eventRegistration-wrapper {
    margin-top:25px;
    text-align: center;
    padding: 15px 15px 15px 15px;
    max-width:100%;
    margin-left:15px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
   
}

.eventRegistration-details {
    border: 1px solid var(--border-color);
    border-radius:5px;
    background-color: white;
    padding: 15px 15px 15px 15px;
}

.eventRegistration-wrapper li {
    padding-bottom:5px;
    padding-top:10px;
    
}

.register-btn {
    width:50%;
    padding:10px 5px 10px 5px;
    margin-top:10px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    background: var(--primary-color);
    color: #fff;
    cursor: pointer !important;
    
}

.register-btn-white {
    width:50%;
    padding:10px 5px 10px 5px;
    margin-top:10px;
    border: 1px solid var(--primary-color);
    outline: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    background: white;
    color: var(--primary-color);
    cursor: pointer;
    
}


@media screen and (max-width:900px) {

    .eventRegistration-wrapper {
        margin-top:25px;
        background-color: white;
        margin-left:0px;
        margin-right:15px;
        text-align: center;
        padding: 15px 15px 15px 15px;
        border: 1px solid var(--border-color);
        border-radius:5px;
        max-width:100%;
     
    }    

}
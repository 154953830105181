.event-organizer-wrapper {
    margin-top:20px;
    padding: 20px 20px 20px 20px;
    border: 1px solid var(--border-color);
    max-width:25%;

}

/* .event-organizer-wrapper img {
    width:55px;
} */


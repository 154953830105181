.publications-wrap {
    text-align: left;
    background-color: #FAFDFF;
    border-top:1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding:15px 15px 15px 15px;
    min-width:auto;
    
}

.publications-header {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom:15px;
    
}

.publication-card {
    margin-top:5px;
    background-color: white;
    padding:15px 15px 15px 15px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.publication-card p {
    padding-bottom:10px;
}
.auth-form {
  max-width: 450px;
  margin: auto;
  padding: 40px;
  border: 1px solid #ddd;
  background: #fff;
}

.auth-form h2 {
  padding: 10px 0;
}

.leftContainer .logo img {
  text-align: center;
  width: 60px;
  margin-top: 0;
  margin-left: 20px;
  padding-bottom: 25px;
}

.mainWrapper {
  width: 100%;
  display: flex;
}

.leftContainer {
  flex: 45%!important;
  padding-top: 15px;
}

.rightContainer {
  flex: 55%!important;
  background-color: #f0f0f0;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the new features container in the right panel */
.features {
  text-align: center;
  max-width: 500px;
}

.features h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #0F1C54;
}

.features p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

.feature-image img {
  width: 100%;
  max-width: 650px;
  height: auto;
  margin: 20px 0;
}

.btn {
  background-color: #0251FB;
  border: none;
  border-radius: 30px;
  padding: 10px 15px 10px 15px;
  color:white;
  font-size:16px;
}


.error {
  color: red;
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .rightContainer {
    display: none;
  }
}

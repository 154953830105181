.event-sponsor-wrapper {
    margin-top:35px;
    padding: 20px 20px 20px 20px;
    border: 1px solid var(--border-color);
    border-radius:5px;
}

.event-sponsor-wrapper img {
    padding-top:25px;
    width:100px;
    height:auto;
}
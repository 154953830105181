

.postList a {
    background-color: white;
    text-decoration: none;
    color:blue;
}

.post-gap {
    height:3px;
    width:100%;
    background-color: #EFF4FF;

}
.resource-item-4 button {
    margin-top:5px;
    background-color:var(--primary-color);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    height: 35px; /* Ensure a fixed height for the button */
    align-self: flex-start; /* Ensure the button remains at the top */
}

.resource-item-4 {
    margin:15px 30px 15px 30px;
    padding:15px 30px 15px 30px;
    background-color: #f6f5f8;
    border-radius:10px;
}

.resource-item-details-4 {
 
    border-left: 2px solid #BDA0FA;
    color:#0b0828 !important;
    padding-left:25px;
}
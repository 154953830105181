.attendees-wrap {

  padding-top:15px;
}


.attendee-header {

}

.attendee-header h2 {
    
}


.attendee-body {
    display: flex;
    flex-wrap:wrap;

}

.attendee-card {
    display: flex;
    margin-top: 15px;
    align-items: center;
    
    border: 1px solid var(--border-color);
    padding:15px 15px 15px 15px;
    margin-right:15px;
    width:235px;
    
}

/* .attendee-img {


} */

.attendee-img img {
 
width:45px;
height:45px;
border-radius:50%;
object-fit: cover !important;
 
 
}
.attendee-info {
  padding-left:15px;
}
.watchContainer {
    flex: 100%;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
  }
  
  .watchHeader {
    flex-grow: 1;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  
  .watch-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  
  .watch-left {
    width: 70%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
  }
  
  /* Responsive Video Container */
  .video-player {
    margin-top: 25px;
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    margin-bottom: 25px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .watch-left h1,
  .watch-left p,
  .watch-left h4 {
    color: #333;
  }
  
  .lesson-text p {
    line-height: 1.6;
    color: #555;
  }
  
  .watch-right {
    width: 30%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    border: solid 1px #dfdfdf;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }
  
  .chat-panel {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .comment-section {
    padding-top: 25px;
    flex-grow: 1;
    overflow-y: scroll;
  }
  
  .comment {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .comment-actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  
  .comment-actions button {
    background-color: transparent;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 14px;
  }
  
  .comment-actions button:hover {
    text-decoration: underline;
  }
  
  .see-replies-button {
    background-color: transparent;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 14px;
  }
  
  .see-replies-button:hover {
    text-decoration: underline;
  }
  
  .replies {
    margin-top: 10px;
    font-size: 14px;
    padding-left: 20px;
    border-left: 2px solid #ddd;
  }
  
  .reply {
    margin-bottom: 10px;
  }
  
  .add-comment {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .add-comment textarea {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    overflow: hidden;
    min-height: 40px;
    max-height: 150px;
  }
  
  .add-comment button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    align-self: flex-start;
  }
  
  /* "Watch Next" section */
  .watch-next {
    margin-top: 40px;
  }
  
  .related-talks {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .related-talk-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .related-talk-details {
    display: flex;
    flex-direction: column;
  }
  
  .related-talk-details h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #444;
  }
  
  .related-talk-details p {
    margin: 5px 0 0;
    color: #666;
  }
  
  .action-btn {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-btn:hover {
    background-color: #008ecc;
  }
  
  /* Updated styles for locked video and chat states */
.locked-video,
.locked-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f3f4f6; /* subtle light grey background */
  border: 1px solid #e5e7eb; /* light, modern border */
  border-radius: 8px;
  padding: 10px; /* reduced padding to eliminate excess gap */
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* modern shadow for depth */
  text-align: center;
}

.locked-video p,
.locked-chat p {
  margin: 10px 0;
  font-size: 16px;
  color: #4b5563;
}

.locked-video button,
.locked-chat button {
  background-color: #2563eb;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.locked-video button:hover,
.locked-chat button:hover {
  background-color: #1d4ed8;
}
.video-player.no-padding {
    margin-top: 0;
    padding-top: 0;
    height: auto; /* Allow the locked container to size itself */
  }
  
  /* Media Query for small screens */
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 28px;
    }
    .watch-wrapper {
      flex-direction: column;
    }
    .watch-left {
      width: 100%;
      padding-right: 0;
    }
    .watch-right {
      width: auto;
      margin-top: 20px;
      padding-right: 0;
      height: auto;
    }
    .comment-section {
      max-height: 200px;
    }
  }
  
.about-section {
    text-align: left;
    background-color: white;
    border-top:1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding:15px 15px 15px 15px;
    margin-bottom:5px;
    width:100%;
    
}

.MuiTabPanel-root {
    padding-left:0px !important;
    padding-right:0px !important;
    margin-left: 0px;
   
}


.sidebar {
    flex: 20%;
	  padding: 10px;
    background: white;
    border-right: 1px solid var(--border-color);
    color: black;
   
  }
  .sidebar-content {
    position: fixed;
    width: inherit;
   
  }
  .sidebar-content .logo img {
    text-align: center;
    width: 60px;
    margin-top:0px;
    margin-left:20px;
  }

  .sidebar .links {
    margin-top: 100px;
    margin-left: 20px;
  }
  .sidebar .links li {
    margin-top: 10px;
    font-size:20px;
  }

  .sidebar-content span {
    padding-top:5px;
    padding-left:5px;
    font-weight:400;
  }
  .sidebar .links a {
    display: flex;
    padding: 10px;
    text-decoration: none;
    width: 100%;
    color: black;
    box-sizing: border-box;
    text-align: center;
  }

  .sidebar .links a.active {
    color: var(--primary-color);
    border-radius: 20px 0 0 20px;
  }

  .sidebar .links a:hover {
    background-color: #DCE7FF;
    width:100%;
    border-radius: 30px;
    color: black;
    transition: color 100ms ease-out;
  }

  .post-actions input {
    display:none;
  }
  @media screen and (max-width: 600px) {

    .sidebar {
       display:none;
      }
      
      .mobileLink {
          display:none;
      }

      .sidebar-content {
    position: fixed;
    width: inherit;
  }
    
  }

  .new-item {
    position: relative;
  }
  
  .new-item .badge {
    background-color: red;
    color: white;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 3px;
    margin-left: 0px;
    vertical-align: super;
  }

  @media screen and (max-width: 1200px) {

    .sidebar {
      max-width: 80px;
      background: white;
      border-right: 1px solid var(--border-color);
      color: black;
      transition: 2s;
    
      }

      .sidebar-content span {
        display:none;
      }

      .mobileLink {
          display:none;
      }

     .sidebar .sidebar-content {
      position: fixed;
      max-width: 80px;
      
      }

      .sidebar .links a:hover {
        background-color: #DCE7FF;
        border-radius: 25%;
        color: black;
        transition: color 100ms ease-out;
      }

      .sidebar .links {
        text-align: center;
      }

      .sidebar-content .logo img {
        text-align: center;
        width: 50px;
        margin-top: 0px;

      }

    
  }


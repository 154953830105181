.onboard-container {
    max-width: 550px;
    margin: 50px auto;
    padding: 20px;
    padding-top:50px;
    background-color: white;
    border: solid 1px var(--border-color);
    border-radius: 10px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:80vh;

  }


  
  .onboard-container h2 {
   padding-bottom:25px;
  }
  
  .onboard-container p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #666;
  }
  
  .onboard-container button {
    padding: 12px 25px;
    font-size: 1rem;
    color: white;
    background-color: var(--primary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px;
  }
  
  .onboard-container button:hover {
    background-color: var(--primary-color);
  }
  
  .onboard-container button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .onboard-container input {
    padding: 10px;
    font-size: 1rem;
    width: 80%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .onboard-container input:focus {
    border-color: var(--primary-color);
  }
  
  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
  }
  
  .onboard-container a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
  }
  
  .onboard-container a:hover {
    text-decoration: underline;
  }
  
  /* Loader styling */
  .onboard-container .loader {
    margin: 20px auto;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid var(--primary-color);
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .onboard-container {
      width: 90%;
      padding: 15px;
    }
  
    .onboard-container h1 {
      font-size: 2rem;
    }
  
    .onboard-container input {
      width: 100%;
    }
  
    .onboard-container button {
      width: 100%;
    }
  }
  /* Ticket Info Section */
.ticket-info-section {
    margin-top: 30px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
   
    text-align: left;
  }
  
  .ticket-info-section h3 {
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .ticket-info-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ticket-info-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    width: 100%;
  }
  
  .ticket-info-image {
    max-width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .ticket-info-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .ticket-info-content p {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .ticket-info-image {
      width: 100%;
      max-width: 100%;
    }
  }
  /* Pricing Container */
.pricing-container {
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    text-align: left !important;
  }

  .pricing-features {
    text-align:left !important;
  }
  
  .pricing-container h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .pricing-box {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--border-color);
  }
  
  .pricing-box .price h3 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .pricing-box .price p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .pricing-features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .pricing-features li {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .pricing-features .checkmark {
    color: green;
    font-size: 1.2rem;
    margin-right: 10px;
  }
  
  .subscribe-btn {
    padding: 12px 25px;
    font-size: 1.1rem;
    background-color: #00aaff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscribe-btn:hover {
    background-color: #008ecc;
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .pricing-box {
      padding: 20px;
    }
  
    .pricing-box .price h3 {
      font-size: 2rem;
    }
  
    .pricing-box .price p {
      font-size: 1rem;
    }
  
    .pricing-features li {
      font-size: 1rem;
      justify-content: left !important;
    }
  
    .subscribe-btn {
      width: 100%;
    }
  }
  
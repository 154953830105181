
.resourcesheader {
    background-image:url('../../assets/event_bg.webp');
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    width:100%;
    padding:75px 0px 75px 0px;
    text-align: center;

}

.resources-details {
    padding: 15px 30px 15px 30px;
    
}

.resource-item-1 {
    text-decoration: none !important;
    margin:15px 30px 15px 30px;
    padding:15px 30px 15px 30px;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius:10px;
}



.resource-item-details-1 {
    text-decoration: none;
    border-left: 2px solid #BDA0FA;
    color:#5d5982 !important;
    padding-left:10px;
}



.resource-item-2 {
    margin:15px 30px 15px 30px;
    padding:15px 30px 15px 30px;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius:10px;
}

.resource-item-details-2 {
 
    border-left: 2px solid #FAAFA0;
    color:#5d5982 !important;
    padding-left:25px;
}

.resource-item-3 {
    margin:15px 30px 15px 30px;
    padding:15px 30px 15px 30px;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius:10px;
}

.resource-item-details-3 {
 
    border-left: 2px solid #A0FAD7;
    color:#5d5982 !important;
    padding-left:10px;
}

.resources-body {
    text-decoration: none;
}

/* Subscription Status Section */
.subscription-status {
    margin: 20px 0;
    text-align: center;
  }
  
  .verified-account, .unverified-account {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.5rem;
  }
  
  .check-circle {
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 50%;
  }
  
  .exclamation-circle {
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 50%;
  }
  
  /* Loading State */
  .subscription-status p {
    font-size: 1.2rem;
    color: #333;
  }
  
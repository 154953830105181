.sponsorWid-wrap {
    background-color:white;
    margin-top:10px;
    padding: 25px 0px 10px 0px;
 
}

.sponsorWid-item {
    margin-top:10px;
    border-radius:10px;
}

.sponsor-img {
    width:100%;
    height:auto;
}


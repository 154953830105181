.eventContainer {
    min-width:auto;
    overflow-y: scroll;
    padding-left:75px;
    padding-right:75px;
}

.verify-btn {

background-color: #0023E8;
border-radius: 15px;
border:none;
padding: 4px 12px;
color:white;
cursor:pointer;
}
.qanda {
    padding-bottom:50px;
}

.qanda .user-post-wrap {
    padding-top:15px;
    padding-bottom:15px;
    padding-left:0px;
    border-bottom:0px;
}


.qanda .post-text {
    padding-top:10px;
}

.qanda .post-img-wrap {
    padding-top:10px;
}

.qanda .post-wrapper {
    border: 1px solid var(--border-color);
}

.eventContainer::-webkit-scrollbar{
    display: none;
  }


.eventHeader {
   
    padding-top:25px;
    padding-left:15px;
    background-color: white;
      
  }

  .event-wrapper {
    display:flex;
  }

  .event-left {
    flex:70%;
    padding-right:10px;
  }

  .event-right {
    flex:30%;
    padding-left:35px;
  }

.eventHeader h1 {
    padding-top:15px;
    font-weight: 700;
    padding-bottom:5px;
}

.event-image {
    background-color: #F3F7FA;
    border-radius: 15px;
}
.event-image img {
    padding-top:15px;
    width:100%;
    height:auto;
    object-fit:contain;

}

.eventHeader h3 {
    padding-top:15px;
    padding-bottom:15px;
}

.live-badge{
 color:white;
 padding: 3px 10px 3px 10px;
 font-size:12px;
 font-weight: bold;
 border-radius:3px;
 background-color: red;
}

.live-badge-small {
    color:white;
    font-size:9px;
    margin-top:3px;
    margin-bottom:3px;
    padding: 2px 5px 2px 5px;
    border-radius:3px;
    background-color: red;
}
.soon-badge {
color:white;
 padding: 5px 5px 5px 5px;
 border-radius:3px;
 background-color: #6236FF;
 font-size:12px;
}

.soon-badge-small {
    color:white;
    font-size:9px;
    margin-top:3px;
    margin-bottom:3px;
    padding: 2px 5px 2px 5px;
    border-radius:3px;
    background-color: #6236FF;
}

.eventHeader a {
    
}

.event-d-t {
    font-weight:bold;
    font-size:20px;
    padding-bottom:25px;
}

.org-details {
    padding: 15px 5px 15px 5px;
    border: 1px solid var(--border-color);
    text-align: center;
    display:flex;
    align-items: center;
    justify-content:space-evenly;

}

.org-details p {
  
    font-weight: bold;
  
}

.event-organizer img {
  
    width:55px;
}

.event-sponsor img {
    width:55px;
}

/* p {
    font-size:22px;
} */
.event-overview {
   
    padding-right:25px;
    margin-top:15px;
}
.event-attendees {
    padding-bottom:35px;
}

.event-overview h2 {
    padding-top:5px;
}

.event-attendees h2 {
    padding-top:5px;
}

.locIcon {
    background-color: #F7F8FA;
    color:#0023E8;
    border-radius:5px;
    height:50px;
}

@media screen and (max-width: 900px) {

    .eventContainer {
        min-width:auto;
        overflow-y: scroll;
        padding-left:0px;
        padding-right:0px;
    }

 @media screen and (max-width: 900px) {
    .event-right {
        flex:30%;
        padding-left:0px;
      
      }
    }
    
.event-wrapper {
    display:block;
  }

  .event-right {
   
    width:100%;
    bottom:0;
  }

}

.eventWid-wrap {
    background-color:#F7F9F9;
    margin-top:15px;
    padding: 25px 10px 25px 10px;
    text-decoration: none;
}

.eventWid-wrap .resource-item-1 {
    font-size:14px;
}

.eventWid-wrap a {
    text-decoration:none;
    color:black;
}

.eventWid-item ul {
text-decoration:none;
}

.eventWid-item .btn {

        padding: 9px 18px;
        outline: none;
        border-radius: 5px;
       font-size:14px;
       border: 1px solid #e4eaea;
        background: #fdffff;
        cursor: pointer;
    
}

.eventWid-wrap h3 {
   
    padding: 5px 10px 10px 10px;
}

.eventWid-item {
    border-bottom :1px solid var(--border-color);
    padding: 10px 10px 10px 10px;
    cursor:pointer;

}


.eventWid-item p {
    font-size: 15px;
    padding-top:3px;
    font-weight:300;
}

.eventWid-item h4 {
    font-weight:400;
}


.eventWid-item span {
    font-size: 14px;
    font-weight:300;
}

.btn-watch-featured {
    background-color:#E7EEEE;
    border-radius: 5px;
    border:none;
    color:#2E6968;
    padding:7px 10px 7px 10px;
    font-weight:bold;
    cursor:pointer;
}
.navbar {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding-right:50px;
    border-bottom: 1px solid var(--border-color);
   
  }
  .navbar ul {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar .logo {
    font-weight: bold;
    color: var(--heading-color);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .navbar .logo img {
    margin-left: 10px;
    width: 75px;
    
  }
  .navbar a {
    color: #333;
    text-decoration: none;
    margin-right: 20px;
  }

  .goBackBtn {
    display:flex;
    align-items:center;
    margin-right: auto;
    text-align: center;
    padding-left:5px;
    margin-left:5px;
    padding-top:5px;

  }

  .back-btn {
background-color: white;
outline:none;
border:0px;
cursor: pointer;
  }


.feed {
    flex: 50%;
    min-width:auto;
    border-right: 1px solid var(--border-color);
	  background-color: white;
    overflow-y: scroll;
}

.feed a {
  word-break: break-word;
  overflow-wrap: break-word;
}


.feed::-webkit-scrollbar {
  display: none;
}

/* New styles for introductory section */
.intro-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
  background-color: #f9f9f9;
}

.intro-left {
  flex: 1;
}

.intro-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.intro-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.intro-buttons {
  display: flex;
  gap: 10px;
}

.intro-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-size: 16px;
}


.ann-banner {
  
 
  color:white;
  z-index:100;
  /* border-bottom: 1px solid var(--border-color); */
 
}

.feedHeader {
  position:sticky;
  top:0;
  background-color: white;
  z-index:100;
  border-bottom: 1px solid var(--border-color);
  padding: 15px 20px;
}


.avator {
  border-radius:100px;
  width:48px;
  margin-right: 10px;
}


.user-post-wrap {
  max-width:100%;
  background: #fff;
  margin: 0 auto;
  border-radius:3px;
  padding: 15px 30px 0px 30px;
  border-bottom: 1px solid #e6ecf0;
  border-top: 1px solid #e6ecf0;
}

.user-post-header {
  display:flex;
  align-items: flex-start;
  font-size:16px;
}

.user-post-header  p{
  font-size:16px;
}

.user-post-header img {
display: inline-block;
overflow: hidden;
width:45px;
height:45px;
border-radius:50%;
object-fit: cover !important;
}

.comment-user-post-header {
  display:flex;
  align-items: flex-start;
  font-size:16px;
  padding-top:10px;
  padding-bottom:10px;
}

.comment-img {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.comment-img img {
  width: 100%;
  height: 100%;
}

.comment-by-user-wrap .comment-user-post-header span {
  color:#657786;
  font-weight:normal;
  margin-left: 5px;
  font-size:15px;
  
}

.comment-user-post-header  p {
  
  font-weight:bold;
  
}

.user-post-header p {
  
  font-weight:bold;
  
}

.user-post-header span {
  color:#657786;
  font-weight:normal;
  margin-left: 5px;
  font-size:14px;
}

.user-comment-details {

  margin-left:40px;
  margin-top:-22px;
  font-size:15px;

}

.user-post-details {
  margin-left:57px;
  margin-top:-20px;
  font-size:16px !important;
}

.user-post-details p {
  font-size:16px !important;
}

.user-post-engagement {
  display:flex;
  margin-left:55px;
  margin-top:10px;
  padding-bottom:5px;
}

.post-likes button {
  background: none;
  border:none;
  color:#657786;
  cursor:pointer;
}
.post-likes p {
  color:#657786;
  margin-top:5px;
}

.post-likes {
  display:flex;
  margin-top:-5px;
}

.post-comments {
  display:flex;
  margin-left:15px;
}

.post-comments p {
  color:#657786;
}

.post-comments button {
  background: none;
  border:none;
  color:#657786;
}


.post-comment-btn button {

 padding: 9px 18px;
  border: 1px solid var(--primary-color);
  outline: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  background: white;
  color: var(--primary-color);
  cursor: pointer;

}

.user-post-comments-wrap {
padding-left:15px;

}

.comments-by-users {
  
  padding-left:85px;
  margin-top:-10px;
}

.comments-by-users .comment-by-user-wrap {

  padding-top:10px;
  padding-bottom:10px;
  border-bottom: 1px solid var(--border-color);
 
}


/* .comment-user-post-header img {
  width:35px;
  border-radius:100%;
  margin-left:35px;
} */

 .comment-img-wrap img {
  border-radius:100px;
  width:35px;
}


.user-post-comments-wrap .post-text textarea {
  min-height:40px;
  font-size:14px;
  
}

.user-post-comments-wrap .post-input-box .post-text {
  
  padding-top:0px;
  
  }


.user-post-comments-wrap .post-img-wrap {

  padding-top:0px;

}

.user-post-comments-wrap img {
  width:35px;
  border-radius:100px;


}


.tweet-wrap {
  max-width:100%;
  background: #fff;
  margin: 0 auto;
  border-radius:3px;
  padding: 30px;
  border-bottom: 1px solid #e6ecf0;
  border-top: 1px solid #e6ecf0;
}

.tweet-header {
  display: flex;
  align-items:flex-start;
  font-size:14px;
}
.tweet-header-info {
  font-weight:bold;
}
.tweet-header-info span {
  color:#657786;
  font-weight:normal;
  margin-left: 5px;
}
.tweet-header-info p {
  font-weight:normal;
  margin-top: 5px;
  
}
.tweet-img-wrap {
  padding-left: 60px;
}

.tweet-info-counts {
  display: flex;
  margin-left: 60px;
  margin-top: 10px;
}
.tweet-info-counts div {
  display: flex;
  margin-right: 20px;
}
.tweet-info-counts div svg {
  color:#657786;
  margin-right: 10px;
}

.post-footer {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid  var(--border-color);
  padding: 10px 25px 10px 25px;
  
}

.post-footer .post-cta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-footer .post-actions {
  display: inline-flex;
}

.post-actions li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-actions span {
  
  padding:5px 5px 5px 5px;
  cursor: pointer;
  border:none;
  background:none;
  color:#5188FF;
}

.post-img-wrap {
  padding-left: 20px;
  padding-top:20px;
}



.post-input-box textarea {
  border:hidden;
  resize:none;
  font-size:16px;
  color:black;
  font-family: Roboto, sans-serif;
  min-height:80px;
}

.post-input-box textarea:focus {
  outline:none;
  
}

.post-input-box .post-text {
  width:100%;
  padding-top:25px;
}

.post-input-box {
  display:flex;
  padding: 10px 10px 10px 10px;
}

.post-cta button {
  padding: 9px 18px;
  border: none;
  outline: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  
}


.flev-wrapper {
  display:flex; 
  max-width:100%;
  padding:25px 15px 25px 15px;
  background-color: #e6ecf0;
}

.flev-thumbnail {

  background-image:url('../../assets/ft_web_5.webp');
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  width:0%;
  border-radius:10px;
}

.flev-details .btn {
  padding: 9px 18px;
  outline: none;
  border-radius: 5px;
 font-size:16px;
  background: var(--primary-color);
  cursor: pointer;

}

.flev-details a {
  text-decoration: none;
  color:white;
}


.flev-details {
  
 padding-left:15px;
 width:100%;
}

.flev-details h3 {
  line-height:25px;
  padding-top:5px;
  padding-bottom:3px;
}

.flev-details p {
  
 font-size:16px;
}


.qa-cta-top a {

text-decoration: none;
color:white;
font-size:16px;
}

.btn-qa {
  margin-top:10px;
  background-color: var(--primary-color);
  color:white;
  padding: 15px 20px 15px 20px;
  outline:0px;
  border:0px;
  border-radius:5px;
  
  }


@media screen and (min-width:600px) {

  .btn-mobile {
   display:none;
  }

}

@media screen and (max-width:600px) {

.btn-mobile {
  background: var(--primary-color);
  padding: 8px 12px;
  position: fixed;
  bottom: 0;
  left: 0;      /* Anchors to the left edge */
  right: 0;     /* Anchors to the right edge */
  color: white;
  cursor: pointer;
  font-size: 1em;
  border: none;
  width: 100%;
  margin: 0;    /* Remove any default margin */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.feed {
  width:100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    border-right: 0px solid var(--border-color);
}


}

@media screen and (max-width:430px){

 
  body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tweet-header {
    flex-direction:column;
  }
  .tweet-header img {
    margin-bottom: 20px;
  }
  .tweet-header-info p {
    margin-bottom: 30px;
  }
  .tweet-img-wrap {
    padding-left: 0;
  }
  .tweet-info-counts {
  display: flex;
  margin-left: 0;
}
.tweet-info-counts div {
  margin-right: 10px;
}
}

/* New styles for the banner image and introductory section */
.banner-image {
  width: 100%;
  height: auto;
  display: block;
}

.intro-section {
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
  background-color: #f9f9f9;
}

.intro-left {
  /* Full width since no right column is needed */
}

.intro-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.intro-buttons {
  display: flex;
  gap: 10px;
}

.intro-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-size: 16px;
}




.App {
  display: flex;
  height:100vh;
  margin-left:auto;
  margin-right:auto;
  max-width: 1300px;
}
.App .container{
  /* flex-grow: 1;
  padding: 0 60px;
  overflow-x:hidden; */
}
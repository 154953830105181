
.app-pending-wrapper {

    align-items: center;
    justify-content: center;
    width:100% !important;
    text-align: center;
    padding-top:100px;
}

.app-pending-wrapper .logo img {
    width:175px;
    height:auto;
    
}
.pending-msg {
    padding-top:25px;
    width:75%;
    align-items: center;
    justify-content: center;
    padding-left:25px;
    padding-right:25px;
    margin:auto;

}
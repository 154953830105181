.avatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar img {
    width: 100%;
    height: 100%;
  }
.addPubs {
    flex: 50%;
    min-width:auto;
    border-right: 1px solid var(--border-color);
	background-color: white;
    overflow-y: scroll;
}


.addPubs::-webkit-scrollbar {
    display: none;
  }
  
  .addPubs h2 {
    padding-top:10px;
    padding-left:15px;
  }

  .addPubs form {
    padding-left:15px;
    padding-right:15px;
  }
.sessionListWrapper {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.verify-alert {
  margin-top: 10px;
  margin-bottom: 10px;
}

.action-btn {
  width: 150px; /* Set a consistent width */
  padding: 10px 15px;
  background-color: #00aaff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

/* Style for the horizontal rule between talks */
.talk-divider {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 0;
}

/* @media screen and (max-width: 900px) {
  .sessionListWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
  }
} */

.sessionListHeader {
  background-image: url('../../assets/nycn_session_list.webp');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 40%; /* Adjusted to match a 2.5:1 image ratio */
  position: relative;
}
.resources-details {
  margin-bottom: 20px;
}

.resources-body {
  margin-top: 20px;
}

.session-item {
  margin-bottom: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.session-header {
  cursor: pointer;
}

.session-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.talks-list {
  margin-top: 15px;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.talk-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 20px;
}

.talk-details {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.talk-details h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #444;
}

.speaker-info {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.speaker-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

.speaker-details {
  display: flex;
  flex-direction: column;
}

.speaker-details p {
  margin: 0;
  color: #666;
}

/* Talk action button column */
.talk-action-column {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.talk-action {
  display: flex;
  align-items: center;
}

.talk-action button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  width: 100px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.talk-action button:hover {
  background-color: #008ecc;
}

.talk-action p {
  margin: 0;
  color: #ff0000;
  font-weight: bold;
}

.disabled-btn {
  background-color: #d3d3d3 !important;
  color: #555;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: not-allowed;
}

.pending-btn {
  background-color: white !important; 
  color: #000 !important;
  border: 1px solid var(--border-color) !important;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: not-allowed;
}

@media screen and (max-width: 900px) {

  .sessionListWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
  }

  .talk-item {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  
  /* Ensure talk details (title, speaker info, and any alert) take full width */
  .talk-details {
    width: 100%;
    margin-bottom: 10px;
  }
  
  /* Stack the call-to-action button below the details */
  .talk-action-column {
    width: 100%;
    margin-top: 10px;
    display: block;
  }
  
  .talk-action button {
    width: 100%;
    margin-top: 5px;
  }
  
  /* Optionally, adjust the alert for full width */
  .verify-alert {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.widgets {
    flex: 30%;
     padding: 0px 10px 10px 10px; 
    /* margin: 5px; */
    background:white;
    overflow-y: scroll;
}

.widgets::-webkit-scrollbar {
  display: none;
}

.widgetsHeader {
    position:sticky;
    top:0;
    background-color: white;
    display:flex;
    align-items: center;
    justify-content: space-between;
    
  }

 .widgetsHeader .user {
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    letter-spacing: 1px;
  }

  .user span {
    padding-left:5px;
  }

  .logoutDiv li{
    list-style-type: none;
  
  }

  .widgetsHeader .logoutDiv {
    display:flex;
    align-items: center;
    justify-content: center;
    
  }

  @media screen and (max-width: 900px) {

    .widgets {
       display:none;
      }
      

    
  }